<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="加入到商品库"
    :visible.sync="show"
    width="550px"
  >
    <el-form size="small" :model="form" ref="form" label-width="140px">
      <el-form-item label="门店采购价设置">
        <el-select clearable :disabled="batch" v-model="form.type">
          <el-option
            v-for="item in optionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="form.type == 1">
        <el-form-item label="新的采购价">
          <el-input
            style="width: 215px"
            type="number"
            @blur="blurHandler1(form.purchasePrice)"
            @input="inputHandler(form.purchasePrice)"
            v-model="form.purchasePrice"
          ></el-input>
        </el-form-item>
        <p class="tips">设置后的采购价为为门店采购商品的价格</p>
      </template>

      <el-form-item v-else label="采购价涨幅">
        <el-input
          maxlength="4"
          @blur="blurHandler2"
          @input="val => (form.ratio = val.replace(/[^0-9.]/g, ''))"
          style="width: 60px"
          v-model="form.ratio"
        ></el-input
        >&nbsp;&nbsp;%
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['batch', 'selections'],
  data() {
    return {
      loading: false,
      optionList: [
        {
          label: '直接提高采购价',
          value: 1
        },
        {
          label: '按比例调价',
          value: 2
        }
      ],
      form: {
        type: 1,
        ratio: 0,
        purchasePrice: 1
      }
    }
  },

  async created() {
    if (this.currentItem) {
      this.form.purchasePrice = this.currentItem.supplyPrice || 0
    }

    if (this.batch) {
      this.form.type = 2
    }
  },

  methods: {
    inputHandler(value) {
      if (
        value.toString().indexOf('.') > 0 &&
        Number(value.toString().split('.')[1].length) > 2
      ) {
        this.form.purchasePrice = Number(value).toFixed(2)
      }
    },
    blurHandler1(val) {
      if (val < this.currentItem.supplyPrice) {
        this.form.purchasePrice = this.currentItem.supplyPrice
      }
    },
    blurHandler2() {
      if (+this.form.ratio > 1000) {
        this.form.ratio = 1000
      }
    },
    async formSubmit() {
      this.loading = true

      try {
        if (this.batch) {
          await this.$api.supplierGoodsSelect.uploadToStoreBatch({
            goodsList: this.selections.map(item => ({
              barNo: item.barNo,
              supplierId: item.supplierId
            })),
            ratio: this.form.ratio
          })
        } else {
          await this.$api.supplierGoodsSelect.uploadToStore({
            barNo: this.currentItem.barNo,
            supplierId: this.currentItem.supplierId,
            purchasePrice: this.form.purchasePrice,
            ratio: this.form.ratio,
            type: this.form.type
          })
        }

        this.$message.success('保存成功')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tips {
  color: brown;
  margin-left: 33px;
}
</style>
