<template>
  <page>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <!-- <el-form-item label="供应商" prop="name">
          <el-input placeholder="供应商" v-model="params.supplierName" />
        </el-form-item> -->

        <el-form-item label="供应商" prop="supplierId">
          <el-select v-model="params.supplierId" clearable>
            <el-option
              v-for="item in supplierList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品名称" prop="goodsName">
          <el-input placeholder="商品名称" v-model="params.goodsName" />
        </el-form-item>

        <el-form-item label="条形码" prop="barNo">
          <el-input placeholder="条形码" v-model="params.barNo" />
        </el-form-item>

        <el-form-item label="品牌" prop="goodsBrand">
          <el-select v-model="params.goodsBrand" clearable filterable>
            <el-option
              v-for="item in brandList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品一级分类" prop="clazzId1">
          <el-select v-model="params.clazzId1" clearable>
            <el-option
              v-for="item in typeList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerInfo">
      <el-button
        v-auth="'middleman:batchSaveGoods'"
        :disabled="!selections.length"
        type="primary"
        @click="uploadToStoreBatch(null)"
        >批量上架到采购库</el-button
      >
    </template>

    <el-table
      v-loading="loading"
      :data="tableData"
      border
      @selection-change="selectHandler"
      height="100%"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
      ></el-table-column>

      <el-table-column label="商品图片">
        <template #default="{ row }">
          <el-popover
            v-if="row.goodsImg"
            effect="light"
            trigger="hover"
            placement="top"
            width="320px"
          >
            <template #default>
              <el-image
                style="width: 300px; height: 300px"
                :src="row.goodsImg"
                fit="scale-down"
              ></el-image>
            </template>
            <template #reference>
              <el-image
                style="height: 30px"
                :src="row.goodsImg"
                fit="scale-down"
                :preview-src-list="[row.goodsImg]"
              ></el-image>
            </template>
          </el-popover>

          <el-image
            v-else
            :src="row.goodsImg"
            class="table-image"
            style="width: 50px; height: 50px"
          >
            <div slot="error" class="image-slot">
              <i
                style="font-size: 50px !important"
                class="el-icon-picture-outline"
              ></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="barNo"
        label="条形码"
      ></el-table-column>
      <el-table-column
        prop="purchaseSpecification"
        label="采购规格"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      ></el-table-column>
      <el-table-column show-overflow-tooltip label="供应单价">
        <template slot-scope="{ row }">
          <span>￥{{ row.supplyPrice || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="供应总价">
        <template slot-scope="{ row }">
          <span
            >￥{{
              Number(
                (row.supplyPrice || 0) * (row.conversionRatio || 0)
              ).toFixed(2)
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="clazzName1"
        label="商品一级分类"
        min-width="100"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="brandName"
        label="品牌"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="supplierName"
        label="供应商"
      ></el-table-column>

      <el-table-column width="200" label="操作">
        <template slot-scope="{ row }">
          <el-button @click="tableProductDetail(row)" type="text"
            >商品详情</el-button
          >
          <el-button
            v-auth="'middleman:saveGoods'"
            @click="uploadToStore(row)"
            type="text"
            >上架到采购库</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      :pageSizeOptions="[1, 10, 20]"
      @change="getData"
    />

    <DetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />

    <AddToStoreModal
      @getData="getData(true)"
      :currentItem="currentItem"
      :selections="selections"
      :batch="batch"
      v-if="addToVisible"
      :visible.sync="addToVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal'
import AddToStoreModal from './components/AddToStoreModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    DetailModal,
    AddToStoreModal
  },
  data() {
    return {
      batch: null,
      loading: false,
      detailVisible: false,
      addToVisible: false,
      currentItem: null,
      selections: [],
      tableData: [],
      brandList: [],
      typeList1: [],
      supplierList: [],
      detailData: null,
      dataForm: null,
      params: {
        name: '',
        goodsName: '',
        goodsBrand: '',
        barNo: '',
        limit: 20,
        page: 1
      },
      totalCount: 0,
      noLoad: false
    }
  },
  watch: {
    showTable(val) {
      this.params.page = 1
      this.params.limit = 20
      this.getData()

      if (!val) {
        this.$nextTick(() => {
          document.querySelector('.productWrap').scrollTop = 0
        })
      }
    }
  },
  mounted() {
    const _this = this
    const el = document.querySelector('.productWrap')

    if (el) {
      el.addEventListener('scroll', function () {
        if (
          el.scrollHeight - el.clientHeight - el.scrollTop <= 0 &&
          !_this.noLoad
        ) {
          _this.params.page = _this.params.page + 1

          _this.debounce(_this.getData(), 300)
        }
      })
    }
  },
  created() {
    this.getSuppliers()
    this.getTypeList()
    this.getBrandList()
    this.getData()
  },
  methods: {
    selectHandler(val) {
      this.selections = val
    },

    tableProductDetail(row) {
      this.currentItem = row
      this.detailVisible = true
    },
    async uploadToStoreBatch() {
      this.batch = true
      this.addToVisible = true
    },
    async uploadToStore(row) {
      this.batch = false
      this.currentItem = row
      this.addToVisible = true
    },

    async getSuppliers() {
      try {
        const res = await this.$api.common.getSuppliers({
          limit: 1000,
          page: 1
        })
        this.supplierList = (res.page.list || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },

    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.typeList1 = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getTypeList()
        // this.typeList1 = (res.list || []).map(item => {
        //   return {
        //     label: item.clazzName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    async getBrandList() {
      try {
        const res = await this.$api.brandList.getGoodsBrandList({
          limit: 1000,
          page: 1
        })

        this.brandList = (res.brandList.list || []).map(item => {
          return {
            label: item.brandName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getBrandList()
        // this.brandList = (res.list || []).map(item => {
        //   return {
        //     label: item.brandName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      try {
        const res = await this.$api.supplierGoodsSelect.getList(sendData)

        const tableData = res.page.list.map(item => {
          item.goodsImg = this.$utils.getImg(item.goodsImg)
          return item
        })

        this.tableData = tableData
        this.totalCount = res.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
